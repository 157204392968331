.message-js {
    @apply fixed top-[calc(64px+1rem)] right-4 mobile:left-4 z-50 my-0 mx-auto;
}

.sl-toast-stack {
    @apply fixed top-[calc(64px+1rem)] right-4 mobile:left-4 z-50 my-0 mx-auto;
}

.sl-toast-stack sl-alert {
    @apply m-0 mb-3;
}