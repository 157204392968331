@layer components {
	.filter {
		@apply mobile:col-span-12 desktop:col-span-3 flex flex-col gap-1;
	}

	.filter__title {
		@apply text-sm font-bold text-text-color-one font-secondary mb-4;
	}

	.filter__dropdown::part(base) {
		@apply border-0;
	}

	.filter__dropdown::part(header) {
		@apply bg-grey-tone-two hover:bg-grey-tone-three font-primary text-sm font-medium text-text-color-one p-0 px-4 min-h-[48px];
	}

	[data-active].filter__dropdown::part(header) {
		@apply bg-primary;
	}

	.filter__dropdown--sub-category-summary {
		@apply pl-2;
	}

	.filter__dropdown::part(summary-icon) {
		@apply text-base text-text-color-one;
	}

	.filter__dropdown::part(content) {
		@apply flex flex-col gap-1 p-0 pt-1;
	}

	.filter__dropdown-item {
		@apply flex items-center gap-3 min-h-[48px] px-4 justify-between bg-grey-tone-two hover:bg-grey-tone-three font-primary text-sm font-medium text-text-color-one;
	}

	.filter__dropdown-sub-item {
		@apply flex items-center gap-3 min-h-[48px] px-8 justify-between bg-grey-tone-one hover:bg-grey-tone-three font-primary text-sm font-medium text-text-color-one;
	}
}
