$root: '/_base' !default;
@config "../tailwind.config.js";
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './colors.css';

@import './components/index.css';
@import '../../_shoppingcart/src/index.css';
