@layer components {
	/* Default */
	.block-google {
		@apply w-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-google__inner {
		@apply w-page max-w-full flex flex-col;
	}

	.block-google__map {
		@apply w-full h-[500px] flex flex-col justify-center;
	}

	.block-google__iframe iframe {
		@apply w-full;
	}

	.block-google__content {
		@apply flex gap-4 flex-col bg-white p-8;
	}

	.block-google__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-google__text {
		@apply text-sm font-secondary text-inherit;
	}

	/* Theme colors */

	.block-google--section-bg-white .block-google__content {
		@apply bg-primary text-text-color-one;
	}
}
