@layer components {
	/* Default */
	.block-gallery {
		@apply w-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-gallery__inner {
		@apply w-page max-w-full;
	}

	.block-gallery__content {
		@apply flex gap-4 flex-col bg-white p-8 mt-4;
	}

	.block-gallery__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-gallery__text {
		@apply text-sm font-secondary text-inherit;
	}

	/* Carousel */

	.block-gallery--carousel .block-gallery__inner {
		@apply flex flex-col;
	}

	/* Mosaic */
	.block-gallery--mosaic .block-gallery__inner {
		@apply mobile:columns-2 gap-4 desktop:columns-3;
	}

	.block-gallery--mosaic .block-gallery__inner img {
		@apply w-full mb-4;
	}

	/* Theme colors */

	.block-gallery--section-bg-one .block-carousel::part(navigation-button) {
		@apply bg-section-bg-one;
	}

	.block-gallery--section-bg-two .block-carousel::part(navigation-button) {
		@apply bg-section-bg-two;
	}

	.block-gallery--section-bg-three .block-carousel::part(navigation-button) {
		@apply bg-section-bg-three;
	}

	.block-gallery--section-bg-four .block-carousel::part(navigation-button) {
		@apply bg-section-bg-four;
	}

	.block-gallery--section-bg-five .block-carousel::part(navigation-button) {
		@apply bg-section-bg-five;
	}

	.block-gallery--section-bg-five .block-gallery__content {
		@apply bg-primary;
	}

	.block-gallery--section-bg-white {
		@apply text-text-color-one;
	}

	.block-gallery--section-bg-white .block-carousel::part(navigation-button) {
		background: rgb(var(--color-section-bg-white));
	}

	.block-gallery--section-bg-white .block-gallery__content {
		@apply bg-primary;
	}
}
