@layer components {
	.product {
		@apply bg-grey-tone-one p-8 flex flex-col justify-between gap-4 col-span-12 sm:col-span-6 md:col-span-4 mobile:p-4 mobile:flex-row;
	}

	.product__title {
		@apply font-primary font-bold text-text-color-one text-xl line-clamp-3;
	}

	.product__title--outside {
		@apply mobile:hidden;
	}

	.product__title--inside {
		@apply desktop:hidden;
	}

	.product__inner {
		@apply w-full flex flex-col gap-4;
	}

	.product__image-container {
		@apply mobile:flex mobile:flex-row mobile:gap-4 mobile:items-center;
	}

	.product__image {
		@apply w-full after:pb-[100%] after:block relative mobile:w-1/4 shrink-0;
	}

	.product__image img {
		@apply absolute max-h-full max-w-full bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 object-cover;
	}

	.product__content {
		@apply desktop:border-b border-grey-tone-three desktop:pb-4 flex flex-row gap-2 items-center mobile:border-t mobile:pt-4 justify-between mobile:flex-wrap;
	}

	.product__price-container {
		@apply flex flex-row gap-2 items-center;
	}

	.product__price {
		@apply text-text-color-one text-xl font-bold font-primary;
	}

	.product__price-advice {
		@apply text-grey-tone-four text-sm font-secondary line-through;
	}

	.product__link {
		@apply desktop:hidden font-medium text-sm text-text-color-one flex flex-row gap-2 font-primary grow justify-end;
	}

	.product .button {
		@apply min-h-[44px] gap-2 w-full mobile:hidden;
	}

	.product .button i {
		@apply text-base;
	}
}
