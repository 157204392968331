.block-thumbnail {
    @apply grid mobile:grid-cols-2 grid-cols-3 gap-4;
}

.block-thumbnail .block-thumbnail__image {
    @apply relative w-full bg-white relative after:pb-[100%] after:block;
}

.block-thumbnail .block-thumbnail__image--loading {
    @apply pointer-events-none;
}

.block-thumbnail .block-thumbnail__image img {
    @apply absolute w-full h-full object-cover max-h-full max-w-full bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2;
}

/* magnific popup styling */

.mfp-container {
    @apply px-[32px];
}

.mfp-bg {
    @apply bg-black/20 opacity-100;
}

.mfp-bottom-bar {
    @apply static bg-white mt-0 max-w-full;
}

.mfp-title {
    @apply text-black pr-0;
}

.mfp-title-container {
    @apply p-8;
}

.mfp-title-text {
    @apply text-2xl font-bold font-primary text-text-color-one mb-2;
}

.mfp-title-description {
    @apply font-secondary text-sm text-text-color-three max-h-[64px] overflow-y-scroll;
}

.mfp-figure:after {
    @apply content-none;
}

.mfp-figure .mfp-img {
    @apply h-full p-0 object-cover mobile:object-contain mobile:h-auto !max-h-[70vh];
}

button.mfp-arrow {
    @apply bg-primary text-text-color-one opacity-100 w-[64px] h-[64px] scale-100 before:opacity-100;
}

button.mfp-arrow:after {
    @apply content-none;
}

button.mfp-arrow-left:before, button.mfp-arrow-right:before {
    @apply static inline-block border-none m-0 w-auto h-auto;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font: var(--fa-font-solid);
}

button.mfp-arrow-left:before {
    @apply content-['\f104'];
}

button.mfp-arrow-right:before {
    @apply content-['\f105'];
}
