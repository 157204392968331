@layer components {
	.radio::part(base) {
		@apply flex items-center;
	}

	.radio::part(control) {
		@apply w-6 h-6;
	}

	.radio::part(label) {
		@apply h-fit;
	}

	.radio::part(control--checked) {
		@apply bg-primary border-primary;
	}
}
