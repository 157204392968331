@layer components {
	/* Default */
	.news-header {
		@apply w-full flex flex-col items-center justify-center;
	}

	.news-header__image {
		@apply bg-cover bg-no-repeat bg-center w-full h-[557px];
	}

	.news-header__inner {
		@apply w-page max-w-full px-4 flex mobile:px-8 flex-col items-center justify-center;
	}

	.news-header__content {
		@apply flex gap-4 flex-col py-8 w-full text-center;
	}

	.news-header__title {
		@apply text-5xl font-bold text-text-color-one font-primary;
	}

	.news-header__text {
		@apply text-sm text-text-color-three font-bold font-primary;
	}
}
