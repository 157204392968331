@layer components {
	.product-info {
		@apply w-full lg:w-1/2 flex flex-col justify-center gap-4 px-8 lg:pr-0 lg:pl-[8%];
	}

	.product-info__top {
		@apply flex flex-col gap-2 desktop:border-b desktop:border-grey-tone-three desktop:pb-4;
	}

	.product-info__brand, .product-info__category {
		@apply text-text-color-one text-xs font-secondary underline;
	}

	.product-info__name {
		@apply text-text-color-one text-2xl font-primary font-bold;
	}

	.product-info__option {
		@apply text-text-color-one text-base font-secondary;
	}

	.product-info__bottom {
		@apply text-2xl flex flex-row items-center gap-1 font-primary;
	}

	.product-info__price-advice {
		@apply text-grey-tone-four line-through;
	}

	.product-info__price {
		@apply text-text-color-one font-bold;
	}

	.product-info .button {
		@apply font-medium;
	}
}
