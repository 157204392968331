@layer components {
	.field::part(form-control-label) {
		@apply font-bold text-xs font-secondary text-text-color-one mb-2;
	}

	.field::part(base) {
		@apply text-sm font-secondary text-text-color-one;
	}

	.field::part(input) {
		@apply px-4;
	}

	.field::part(textarea) {
		@apply h-[144px];
	}

	.field::part(combobox) {
		@apply h-[50px];
	}

	.field::part(form-control-label)::after {
		@apply text-alert-danger;
	}

	.field::part(form-control-help-text) {
		@apply text-xs font-secondary text-grey-tone-four mt-2;
	}

	.field--file::part(input) {
		@apply py-3;
	}
}
