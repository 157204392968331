@layer components {
	.checkbox {
		@apply flex items-center gap-2;
	}

	.checkbox__replacement {
		@apply border border-grey-tone-three rounded h-[24px] w-[24px] flex items-center justify-center text-base text-white cursor-pointer bg-white;
	}

	.checkbox__label {
		@apply text-sm text-text-color-one font-secondary;
	}

	.checkbox__input {
		@apply hidden;
	}

	.checkbox__input:checked + .checkbox__replacement {
		@apply text-primary;
	}
}
