@layer components {
	.page-footer {
		@apply flex flex-col items-center w-full bg-footer border-t border-grey-tone-three;
	}

	.page-footer__inner {
		@apply flex flex-row mobile:flex-col w-page desktop:gap-4 max-w-full desktop:pt-28 desktop:pb-20 px-4 mobile:px-8 justify-between;
	}

	.page-footer__sitemap {
		@apply mobile:w-full mobile:border-b mobile:border-grey-tone-three mobile:text-center mobile:py-8;
	}

	.page-footer__title {
		@apply text-text-color-one text-xl font-primary font-bold pb-4;
	}

	.page-footer__content {
		@apply flex flex-col gap-4 text-text-color-one text-base font-secondary;
	}

	.page-footer__content a {
		@apply text-inherit hover:underline;
	}

	.page-footer__socials {
		@apply flex flex-row gap-4 text-2xl mobile:justify-center;
	}

	.page-footer__socials a {
		@apply text-text-color-one hover:text-primary;
	}

	.page-footer__socials i {
		@apply text-2xl;
	}

	.page-footer__bottom {
		@apply flex flex-row mobile:flex-col items-center justify-between w-page max-w-full desktop:border-t desktop:border-grey-tone-three py-8 px-4 mobile:px-8 mobile:py-4 text-sm text-text-color-one;
	}

	.page-footer__bottom-left {
		@apply flex flex-row items-center justify-start;
	}

	.page-footer__bottom-right {
		@apply flex flex-row mobile:flex-col items-center desktop:gap-1 mobile:pt-4 justify-end;
	}

	.page-footer__bottom-right span {
		@apply mobile:hidden;
	}

	.page-footer__bottom-right span:last-child {
		@apply hidden;
	}
}
