@layer components {
	.checkbox-button__replacement {
		@apply border border-black rounded h-[48px] px-4 flex flex-row gap-2 items-center text-sm text-text-color-one cursor-pointer;
	}

	.checkbox-button__checked, .checkbox-button__unchecked {
		@apply text-base;
	}

	.checkbox-button__checked {
		@apply hidden;
	}

	.checkbox-button__input {
		@apply hidden;
	}

	.checkbox-button__input:checked + .checkbox-button__replacement {
		@apply bg-primary border-primary;
	}

	.checkbox-button__input:checked + .checkbox-button__replacement .checkbox-button__checked {
		@apply flex;
	}

	.checkbox-button__input:checked + .checkbox-button__replacement .checkbox-button__unchecked {
		@apply hidden;
	}
}
