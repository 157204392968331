@layer components {
	.news-item {
		@apply bg-grey-tone-one flex flex-col justify-between col-span-12 sm:col-span-6 md:col-span-4 justify-between;
	}

	.news-item__inner {
		@apply w-full flex flex-col;
	}

	.news-item__image {
		@apply w-full after:pb-[100%] after:block relative;
	}

	.news-item__image img {
		@apply absolute max-h-full max-w-full bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 object-cover w-full h-full object-cover;
	}

	.news-item__content {
		@apply p-8 pb-4 flex flex-col gap-4;
	}

	.news-item__title {
		@apply font-primary font-bold text-text-color-one text-xl line-clamp-2;
	}

	.news-item__text {
		@apply font-secondary text-grey-tone-four text-sm line-clamp-3;
	}

	.news-item__info {
		@apply text-text-color-one text-sm font-primary font-bold w-full flex items-center gap-3 p-8 pt-0;
	}

	.news-item__info-image {
		@apply h-[38px] w-[38px] shrink-0 rounded-full overflow-hidden;
	}

	.news-item__info-image img {
		@apply w-full h-full object-cover;
	}
}
