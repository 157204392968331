@layer components {
	/* Default */
	.block-music {
		@apply w-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-music__inner {
		@apply w-page max-w-full gap-4;
	}

	.block-music__iframe {
		@apply flex flex-col justify-center;
	}

	.block-music--left .block-music__iframe *,
	.block-music--right .block-music__iframe *, 
	.block-music--center .block-music__iframe * {
		@apply h-full min-h-[352px];
	}

	.block-music--left .block-music__content,
	.block-music--right .block-music__content,
	.block-music__iframe--full {
		@apply desktop:min-h-[352px];
	}

	.block-music__content {
		@apply flex gap-4 flex-col justify-center py-20 px-[16%] mobile:p-8;
		background: rgb(var(--color-section-bg-white));
	}

	.block-music--center .block-music__content {
		@apply px-[8%] mobile:p-8;
	}

	.block-music__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-music__text {
		@apply text-sm font-secondary text-inherit;
	}

	/* Layout right */
	.block-music--right .block-music__inner {
		@apply grid grid-cols-12;
	}

	.block-music--right .block-music__iframe {
		@apply order-2 mobile:col-span-12 col-span-6;
	}

	.block-music--right .block-music__iframe--full {
		@apply col-span-12;
	}

	.block-music--right .block-music__content {
		@apply order-1 mobile:col-span-12 col-span-6;
	}

	/* Layout center */
	.block-music--center .block-music__inner {
		@apply flex flex-col;
	}

	.block-music--center .block-music__iframe {
		@apply order-1;
	}

	.block-music--center .block-music__content {
		@apply order-2;
	}

	/* Layout left */
	.block-music--left .block-music__inner {
		@apply grid grid-cols-12;
	}

	.block-music--left .block-music__iframe {
		@apply mobile:col-span-12 col-span-6;
	}

	.block-music--left .block-music__iframe--full {
		@apply col-span-12;
	}

	.block-music--left .block-music__content {
		@apply mobile:col-span-12 col-span-6;
	}

	/* Theme colors */
	.block-music--section-bg-white {
		@apply text-text-color-one;
	}

	.block-music--section-bg-white .block-music__content {
		@apply bg-primary;
	}
}
