@layer components {
	.accordion::part(base) {
		@apply border-0 bg-transparent;
	}

	.accordion::part(header) {
		@apply border-0 p-0 outline-none pb-4;
	}

	.accordion::part(summary-icon) {
		@apply text-text-color-one mr-4;
		rotate: none;
	}

	.accordion::part(content) {
		@apply p-4 pt-0 flex flex-col gap-4;
	}
}
