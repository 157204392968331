@layer components {
	.pagination {
		@apply col-span-12 border border-grey-tone-three p-4 gap-4 flex flex-row items-center justify-between;
	}

	.pagination__pages {
		@apply flex flex-row flex-wrap items-center gap-2;
	}

	.pagination__page {
		@apply text-sm font-secondary text-text-color-one hover:bg-primary hover:font-bold w-[32px] h-[32px] flex items-center justify-center bg-grey-tone-two;
	}

	.pagination__page--active {
		@apply bg-primary;
	}

	.pagination__prev, .pagination__next {
		@apply text-text-color-one text-sm font-secondary hover:underline cursor-pointer;
	}

	.pagination__prev i, .pagination__next i {
		@apply desktop:hidden text-2xl;
	}

	.pagination__prev span, .pagination__next span {
		@apply mobile:hidden;
	}
}
