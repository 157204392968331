@layer components {
	/* Default */
	.block-video {
		@apply w-auto h-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-video__inner {
		@apply w-page h-full max-w-full flex flex-row gap-4;
	}

	.block-video__iframe {
		@apply w-full flex flex-col justify-center;
	}

	.block-video__iframe iframe {
		@apply w-full aspect-video;
	}

	.block-video__content {
		@apply flex flex-col gap-4 bg-white p-8 grow;
	}

	.block-video__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-video__text {
		@apply text-sm font-secondary text-inherit;
	}

	.block-video__item {
		@apply w-full h-full flex flex-col;
	}

	.section__col--w-full .block-video__item {
		@apply desktop:flex-row;
	}

	.section__col--w-full .block-video__iframe, .section__col--w-full .block-video__content {
		@apply desktop:w-1/2;
	}

	.section__col--w-full .block-video__iframe--full {
		@apply w-full;
	}

	.section__col--w-full .block-video__content {
		@apply desktop:justify-center;
	}

	/* Theme colors */
	.block-video--section-bg-white {
		@apply text-text-color-one;
	}

	.block-video--section-bg-white .block-video__content {
		@apply bg-primary;
	}
}
