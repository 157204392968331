@layer components {
	.button {
		@apply flex px-2 gap-2 items-center justify-center text-sm font-bold font-primary min-h-[50px] w-[305px] max-w-full cursor-pointer transition-colors hover:bg-grey-tone-two;
	}

	.button--primary {
		@apply bg-primary text-text-color-one border border-transparent hover:bg-white hover:border-grey-tone-three;
	}

	.button--secondary {
		@apply bg-white text-text-color-one border border-grey-tone-three hover:bg-primary;
	}

	.button:disabled {
		@apply cursor-not-allowed bg-grey-tone-five border-grey-tone-five text-text-color-one;
	}

	i {
		@apply text-base;
	}
}
