@layer components {
	/* Default */
	.block-form {
		@apply w-full flex items-center justify-center;
	}

	.block-form__inner {
		@apply w-page max-w-full hidden;
	}

	.block-form__content {
		@apply flex gap-4 flex-col items-center text-text-color-one;
	}

	.block-form__title {
		@apply text-2xl font-bold font-primary;
	}

	.block-form__text {
		@apply text-sm font-secondary text-inherit;
	}

	/* Text align */
	.block-form--text-center {
		@apply text-center;
	}

	.block-form--text-right {
		@apply text-right;
	}

	.block-form--text-justify {
		@apply text-justify;
	}

	/* Fields */
	.block-form__fields {
		@apply w-page max-w-full flex flex-col gap-5 py-20;
	}

	.block-from__fields-overlay {
		@apply hidden;
	}

	.block-form__fields-inner {
		@apply bg-white p-4 pt-8;
	}

	.block-form__fields-title {
		@apply text-left text-xl font-primary text-text-color-one font-bold;
	}

	.block-form__fields-content {
		@apply border-b border-grey-tone-three flex gap-4 flex-col text-left pb-8 mb-8;
	}

	.block-form__close,
	.block-form__fields-overlay {
		@apply hidden;
	}

	.block-form__form {
		@apply w-full grid grid-cols-12 gap-4 text-left;
	}

	/* Lightbox */
	.block-form--lightbox {
		@apply py-40 text-text-color-one;
	}

	.block-form--lightbox .block-form__fields-content {
		@apply flex-row items-center justify-between sticky top-0 left-0 right-0 z-20 pt-8 px-8 border-0 after:border-b after:border-grey-tone-three after:absolute after:w-[calc(100%-4rem)] after:bottom-0;
		background: rgb(var(--color-section-bg-white));
	}

	.block-form--lightbox .block-form__form {
		@apply px-8;
	}

	.block-form--lightbox .block-form__close {
		@apply flex text-black w-[44px] h-[44px] items-center justify-center border border-primary shrink-0 cursor-pointer transition-colors hover:bg-primary;
	}

	.block-form--lightbox .block-form__inner {
		@apply flex justify-center;
	}

	.block-form--lightbox .block-form__fields {
		@apply hidden fixed top-0 right-0 bottom-0 left-0 z-20 w-full p-0;
	}

	.block-form--lightbox .block-form__fields-overlay {
		@apply fixed top-0 right-0 bottom-0 left-0 z-20 bg-black/50 cursor-pointer;
	}

	.block-form--lightbox .block-form__fields-inner {
		@apply fixed z-20 bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 w-[740px] max-w-[90vw] max-h-[90vh] p-0 pb-8 overflow-y-auto;
	}

	.block-form--lightbox .block-form__fields-text {
		@apply hidden;
	}

	/* Lightbox active */
	.block-form--lightbox-active .block-form__fields,
	.block-form--lightbox-active .block-form__fields-overlay {
		@apply flex;
	}

	.block-form__message {
		@apply fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-20;
	}

	.block-form__message-container {
		@apply flex flex-col items-center justify-center bg-white p-4 gap-4 border border-grey-tone-three relative max-h-full w-[337px] max-w-full;
	}

	.block-form__message-xmark {
		@apply absolute flex items-center justify-center top-0 right-0 h-[64px] w-[64px] cursor-pointer;
	}

	.block-form__message-xmark i {
		@apply text-2xl;
	}
	
	.block-form__message-text {
		@apply text-text-color-one overflow-y-auto;
	}

	.block-form__message-title {
		@apply text-2xl font-bold font-primary text-text-color-one px-[48px];
	}

	.block-form__message-overlay {
		@apply fixed bg-black/20 top-0 right-0 bottom-0 left-0 cursor-pointer;
	}

}
