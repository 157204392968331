@layer components {
	.phone-field {
		@apply text-sm font-secondary;
	}

	.phone-field > div {
		@apply w-full;
	}

	.phone-field input {
		@apply w-full h-[50px] border border-grey-tone-three rounded px-4;
	}

	.phone-field .iti__selected-dial-code {
		@apply pt-[1px];
	}
}
