@layer components {
	.product-images {
        @apply bg-grey-tone-one flex flex-col lg:flex-row w-full lg:w-1/2 overflow-hidden my-8 lg:my-0;
    }

    .product-images__thumbnails {
        @apply flex desktop:flex-col mobile:flex-row desktop:gap-4 mobile:gap-4 desktop:w-1/6 desktop:p-4 mobile:p-8 mobile:pt-0 pr-6 mobile:w-full mobile:order-2 mobile:grid mobile:grid-cols-5;
    }

    .product-images__thumbnail {
        @apply bg-white rounded p-1 border-2 border-white relative after:pb-[100%] after:block cursor-pointer;
    }

    .product-images__thumbnail--active {
        @apply border-primary;
    }

    .product-images__thumbnail img {
        @apply absolute w-full h-full object-cover max-h-[90%] max-w-[90%] bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2;
    }

    .product-images__carousel {
        @apply h-full w-full;
    }

    .product-images__carousel-container {
        @apply desktop:w-5/6 desktop:pt-8 mobile:px-2 mobile:pb-2 mobile:pt-6 desktop:p-4 flex flex-col justify-center mobile:w-full mobile:order-1;
    }

    .product-images__carousel-item, sl-carousel-item {
        @apply h-full w-full;
    }

    .product-images__image {
        @apply relative after:block w-full h-full;
    }

    .product-images__image img {
        @apply absolute max-h-full max-w-full bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2;
    }
}
