@layer components {
	/* Default */
	.block-download {
		@apply w-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-download__inner {
		@apply w-page max-w-full grid grid-cols-12 gap-4;
	}

	.block-download__item {
		@apply flex gap-4 flex-col bg-white p-8 col-span-12 justify-between items-center;
	}

	.block-download__item-bottom {
		@apply flex flex-col gap-4 w-full max-w-[616px];
	}

	.block-download__content {
		@apply flex gap-4 flex-col w-full max-w-[616px];
	}

	.block-download__title {
		@apply text-2xl font-bold font-primary text-text-color-one;
	}

	.block-download__text {
		@apply text-sm font-secondary text-inherit;
	}

	.block-download__file {
		@apply text-sm font-primary text-text-color-one/50 font-medium;
	}

	/* Text align */
	.block-download--center {
		@apply text-center;
	}

	.block-download--right {
		@apply text-right;
	}

	.block-download--justify {
		@apply text-justify;
	}

	/* Theme colors */
	.block-download--section-bg-white {
		@apply text-text-color-one;
	}

	.block-download--section-bg-white .block-download__item {
		@apply bg-primary;
	}
}
