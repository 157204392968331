/*  
*   Shoelace file that sets the standard look.
*   This styling file is for shoelace components only, so no CSS classes.
*/

sl-drawer::part(header) {
    @apply font-bold text-2xl font-primary text-text-color-one;
}

sl-drawer::part(close-button__base) {
    @apply flex items-center justify-center text-base text-text-color-one border border-solid border-primary rounded-none w-[44px] h-[44px] transition-colors hover:bg-primary;
}

sl-drawer::part(footer) {
    @apply text-left;
}

sl-details::part(summary), sl-details div[slot="summary"] {
    @apply w-11/12;
}

/* this is a custom shoelace style */
.sl-details__anchor {
    @apply block w-full py-[14px];
}