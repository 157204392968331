@layer components {
	/* Default */
	.block-image {
		@apply w-full flex items-center justify-center py-20 text-text-color-three;
	}

	.block-image__inner {
		@apply w-page max-w-full grid grid-cols-12 gap-4;
	}

	.block-image__item {
		@apply flex flex-col col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3 justify-between items-center;
	}

	.block-image__media {
		@apply relative w-full after:pb-[100%] after:block bg-cover bg-center bg-no-repeat;
	}

	.block-image__media img {
		@apply w-full h-full object-cover absolute top-0 right-0 bottom-0 left-0;
	}

	.block-image__content {
		@apply flex flex-col gap-4 bg-white p-8;
	}

	.block-image__scroll {
		@apply flex flex-col gap-4 w-full;

		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
	
	.block-image__scroll::-webkit-scrollbar {
		display: none;
	}

	.block-image__title {
		@apply text-xl font-bold font-primary text-text-color-one;
	}

	.block-image__text {
		@apply text-sm font-secondary text-inherit;
	}

	.block-image__toggle {
		@apply hidden;
	}

	.block-image__toggle i {
		@apply text-2xl;
	}

	/* square - portrait - landscape  */

	.block-image--portrait-above .block-image__media,
	.block-image--portrait-inside .block-image__media,
	.block-image--portrait-below .block-image__media {
		@apply after:pb-[133%];
	}

	.block-image--landscape-above .block-image__media,
	.block-image--landscape-inside .block-image__media,
	.block-image--landscape-below .block-image__media {
		@apply after:pb-[67%];
	}

	/* above */
	.block-image--square-above .block-image__item,
	.block-image--portrait-above .block-image__item,
	.block-image--landscape-above .block-image__item, 
	.block-image--circle-above .block-image__item {
		@apply justify-end;
	}

	/* below */
	.block-image--square-below .block-image__item,
	.block-image--portrait-below .block-image__item,
	.block-image--landscape-below .block-image__item, 
	.block-image--circle-below .block-image__item {
		@apply justify-start;
	}

	/* square - portrait - landscape above */

	.block-image--square-above .block-image__media,
	.block-image--portrait-above .block-image__media,
	.block-image--landscape-above .block-image__media {
		@apply order-2;
	}

	.block-image--square-above .block-image__content,
	.block-image--portrait-above .block-image__content,
	.block-image--landscape-above .block-image__content {
		@apply w-full h-full order-1 relative z-10 after:absolute after:top-[100%] after:w-0 after:h-0 after:border-solid after:border-t-white after:border-t-[19px] after:border-x-transparent after:border-x-[48px] after:border-b-0;
	}

	/* square - portrait - landscape inside */

	.block-image--square-inside .block-image__item,
	.block-image--portrait-inside .block-image__item,
	.block-image--landscape-inside .block-image__item {
		@apply relative;
	}

	.block-image--square-inside .block-image__toggle,
	.block-image--portrait-inside .block-image__toggle,
	.block-image--landscape-inside .block-image__toggle {
		@apply flex h-[64px] w-[64px] items-center justify-center bg-white/90 absolute bottom-0 right-0 text-text-color-one cursor-pointer;
	}

	.block-image--square-inside .block-image__content,
	.block-image--portrait-inside .block-image__content,
	.block-image--landscape-inside .block-image__content {
		@apply absolute top-[32px] right-[64px] bottom-[64px] left-[32px] overflow-hidden p-4 bg-white/90;
	}

	.block-image--square-inside .block-image__scroll,
	.block-image--portrait-inside .block-image__scroll,
	.block-image--landscape-inside .block-image__scroll {
		@apply max-h-full overflow-y-auto;
	}

	/* square - portrait - landscape below */

	.block-image--square-below .block-image__media,
	.block-image--portrait-below .block-image__media,
	.block-image--landscape-below .block-image__media {
		@apply order-1;
	}

	.block-image--square-below .block-image__content,
	.block-image--portrait-below .block-image__content,
	.block-image--landscape-below .block-image__content {
		@apply w-full h-full order-2 relative after:absolute after:bottom-[100%] after:w-0 after:h-0 after:border-solid after:border-b-white after:border-b-[19px] after:border-x-transparent after:border-x-[48px] after:border-t-0;
	}

	/* circle-above */

	.block-image--circle-above .block-image__item {
		@apply gap-4;
	}

	.block-image--circle-above .block-image__media {
		@apply rounded-full order-2 overflow-hidden;
	}

	.block-image--circle-above .block-image__content {
		@apply order-1 w-full;
	}

	/* circle-inside */

	.block-image--circle-inside .block-image__item {
		@apply relative mb-[34px];
	}

	.block-image--circle-inside .block-image__media {
		@apply rounded-full overflow-hidden;
	}

	.block-image--circle-inside .block-image__content {
		@apply absolute -top-[1px] -right-[1px] -bottom-[1px] -left-[1px] rounded-full bg-white/90 justify-center p-16;
	}

	.block-image--circle-inside .block-image__scroll {
		@apply max-h-full overflow-y-auto;
	}

	.block-image--circle-inside .block-image__toggle {
		@apply flex h-[64px] w-[64px] items-center justify-center bg-white rounded-full absolute -bottom-[34px] right-0 text-text-color-one cursor-pointer;
	}

	/* circle-below */

	.block-image--circle-below .block-image__item {
		@apply gap-4;
	}

	.block-image--circle-below .block-image__media {
		@apply rounded-full order-1 overflow-hidden;
	}

	.block-image--circle-below .block-image__content {
		@apply order-2 w-full;
	}

	/* Caption inside Slide In */
	.block-image--slidein.block-image--square-inside .block-image__item,
	.block-image--slidein.block-image--portrait-inside .block-image__item,
	.block-image--slidein.block-image--landscape-inside .block-image__item,
	.block-image--slidein.block-image--circle-inside .block-image__item {
		@apply pb-[98px] mb-0;
	}

	.block-image--slidein.block-image--square-inside .block-image__content,
	.block-image--slidein.block-image--portrait-inside .block-image__content,
	.block-image--slidein.block-image--landscape-inside .block-image__content,
	.block-image--slidein.block-image--circle-inside .block-image__content {
		@apply left-0 right-0 top-auto bottom-0 justify-center p-8 pr-[85px] h-[98px] transition-all;
	}

	.block-image--slidein .block-image__title {
		@apply line-clamp-2;
	}

	.block-image--slidein .block-image__content--active .block-image__title {
		@apply line-clamp-none;
	}

	.block-image--slidein.block-image--square-inside .block-image__content--active,
	.block-image--slidein.block-image--portrait-inside .block-image__content--active,
	.block-image--slidein.block-image--landscape-inside .block-image__content--active,
	.block-image--slidein.block-image--circle-inside .block-image__content--active {
		@apply h-full;
	}

	.block-image--slidein.block-image--square-inside .block-image__scroll,
	.block-image--slidein.block-image--portrait-inside .block-image__scroll,
	.block-image--slidein.block-image--landscape-inside .block-image__scroll,
	.block-image--slidein.block-image--circle-inside .block-image__scroll {
		@apply h-full overflow-hidden;
	}

	.block-image--slidein.block-image--square-inside .block-image__content--active .block-image__scroll,
	.block-image--slidein.block-image--portrait-inside .block-image__content--active .block-image__scroll,
	.block-image--slidein.block-image--landscape-inside .block-image__content--active .block-image__scroll,
	.block-image--slidein.block-image--circle-inside .block-image__content--active .block-image__scroll {
		@apply overflow-y-auto;
	}

	/* Theme colors */

	.block-image--section-bg-white {
		@apply text-text-color-one;
	}

	.block-image--section-bg-white.block-image--square-above .block-image__content,
	.block-image--section-bg-white.block-image--square-inside .block-image__content,
	.block-image--section-bg-white.block-image--square-below .block-image__content,
	.block-image--section-bg-white.block-image--portrait-above .block-image__content,
	.block-image--section-bg-white.block-image--portrait-inside .block-image__content,
	.block-image--section-bg-white.block-image--portrait-below .block-image__content,
	.block-image--section-bg-white.block-image--landscape-above .block-image__content,
	.block-image--section-bg-white.block-image--landscape-inside .block-image__content,
	.block-image--section-bg-white.block-image--landscape-below .block-image__content {
		@apply bg-primary;
	}

	.block-image--section-bg-white.block-image--square-below .block-image__content,
	.block-image--section-bg-white.block-image--portrait-below .block-image__content,
	.block-image--section-bg-white.block-image--landscape-below .block-image__content {
		@apply after:border-b-primary;
	}

	.block-image--section-bg-white.block-image--square-above .block-image__content,
	.block-image--section-bg-white.block-image--portrait-above .block-image__content,
	.block-image--section-bg-white.block-image--landscape-above .block-image__content {
		@apply after:border-t-primary;
	}

	.block-image--section-bg-white.block-image--circle-above .block-image__content,
	.block-image--section-bg-white.block-image--circle-inside .block-image__content,
	.block-image--section-bg-white.block-image--circle-below .block-image__content {
		@apply bg-primary/90;
	}

	.block-image--section-bg-white.block-image--square-inside .block-image__toggle,
	.block-image--section-bg-white.block-image--portrait-inside .block-image__toggle,
	.block-image--section-bg-white.block-image--landscape-inside .block-image__toggle,
	.block-image--section-bg-white.block-image--circle-inside .block-image__toggle {
		@apply bg-primary;
	}

	/* Columns */
	.block-image--size-medium .block-image__item {
		@apply col-span-12 sm:col-span-6 md:col-span-4;
	}

	.block-image--size-large .block-image__item {
		@apply col-span-12 sm:col-span-6;
	}

	.block-image--full .block-image__item {
		@apply col-span-12;
	}
}
