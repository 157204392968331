@layer components {
	.guide {
		@apply w-full flex flex-row justify-center border-t border-b border-grey-tone-three overflow-x-auto;
	}

	.guide__inner {
		@apply w-page max-w-full flex flex-row items-center text-xs font-bold font-secondary text-text-color-one pl-4;
	}

	.guide__item {
		@apply p-4 pl-0 flex flex-row flex-nowrap items-center gap-4 whitespace-nowrap;
	}

	.guide__item:hover span {
		@apply underline;
	}

	.guide i {
		@apply text-grey-tone-three text-base;
	}

	.guide__item:last-child {
		@apply font-normal;
	}

	.guide__item:last-child i {
		@apply hidden;
	}
}
