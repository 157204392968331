@layer components {
	.product-description {
		@apply w-full flex flex-row items-center justify-center;
	}

	.product-description__inner {
		@apply w-page max-w-full px-4 mobile:px-8 pb-16 flex flex-col gap-8;
	}

	.product-description__section {
		@apply flex flex-col gap-4 text-sm font-secondary text-text-color-three;
	}

	.product-description__section h1,
	.product-description__section h2,
	.product-description__section h3,
	.product-description__section h4,
	.product-description__section h5,
	.product-description__section h6 {
		@apply text-2xl font-primary text-text-color-one;
	}

}
