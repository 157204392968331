@layer components {
	.block-carousel--gallery {
		@apply px-0;
	}

	.block-carousel--gallery::part(base) {
		@apply gap-0;
	}

	.block-carousel--gallery::part(navigation-button) {
		@apply absolute bg-primary rounded-none m-0 h-[64px] w-[64px] flex items-center justify-center;
	}

	.block-carousel--gallery::part(navigation-button--previous) {
		@apply left-[18px] mobile:hidden;
	}

	.block-carousel--gallery::part(navigation-button--next) {
		@apply right-[18px] mobile:right-[76px];
	}

	.block-carousel--gallery::part(scroll-container) {
		--scroll-hint: 116px;

		scroll-padding-inline: unset;
		scroll-padding-left: var(--scroll-hint);
		scroll-padding-right: var(--scroll-hint);
	}

	@media screen and (max-width: 1023px) {
		.block-carousel--gallery::part(scroll-container) {
			--scroll-hint: 58px;

			scroll-padding-left: unset;
		}
	}
}
