@layer components {
	/* Default */
	.page-header {
		@apply w-full flex items-center justify-center relative desktop:bg-primary;
	}

	.page-header__image {
		@apply bg-cover bg-no-repeat bg-center absolute top-0 right-0 bottom-0 left-0 hidden;
	}

	.page-header__image img {
		@apply w-full h-full;
	}

	.page-header__inner {
		@apply flex flex-row items-center justify-between mobile:p-0 z-10;
	}

	.page-header__content {
		@apply flex gap-4 flex-col py-20 mobile:p-8;
	}

	.page-header__title {
		@apply text-4xl font-primary;
	}

	.page-header__text {
		@apply text-base font-secondary;
	}

	/* Large */
	.page-header--large {
		@apply min-h-[833px] mobile:min-h-0;
	}

	.page-header--large .page-header__inner {
		@apply desktop:px-4 justify-end mobile:w-full w-page max-w-full;
	}

	.page-header--large .page-header__content {
		@apply w-1/2 px-[8%] min-h-[680px] my-8 py-16 justify-center mobile:w-full mobile:m-0 mobile:p-8 mobile:min-h-0 mobile:justify-end mobile:bg-primary;
	}

	.page-header--large.page-header--image .page-header__content {
		@apply mobile:min-h-[90vh];
	}

	/* Small */
	.page-header--small.page-header--image {
		@apply mobile:pt-[50vh];
	}

	.page-header--small .page-header__image {
		@apply mobile:bottom-auto mobile:h-[50vh];
	}

	.page-header--small .page-header__inner {
		@apply mobile:bg-primary w-full flex flex-row items-center justify-center;
	}

	.page-header--small .page-header__content {
		@apply px-4 w-page max-w-full;
	}

	/* Theme colors */
	.page-header--text-color-one {
		@apply text-text-color-one;
	}

	.page-header--text-color-one.page-header--large .page-header__content {
		@apply desktop:bg-white/50;
	}

	.page-header--text-color-one.page-header--large.page-header--image .page-header__content {
		@apply bg-white/50;
	}

	.page-header--text-color-one.page-header--small.page-header--image .page-header__inner {
		@apply desktop:bg-white/50;
	}

	.page-header--text-color-two {
		@apply text-text-color-two;
	}

	.page-header--text-color-two.page-header--large .page-header__content {
		@apply desktop:bg-black/50;
	}

	.page-header--text-color-two.page-header--large.page-header--image .page-header__content {
		@apply bg-black/50;
	}

	.page-header--text-color-two.page-header--small.page-header--image .page-header__inner {
		@apply desktop:bg-black/50;
	}

	/* Image */
	.page-header.page-header--image {
		@apply desktop:bg-white;
	}

	.page-header.page-header--image .page-header__image {
		@apply block;
	}
}
