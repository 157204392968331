.preview {
    @apply flex flex-col h-screen w-screen;
}

.preview__nav {
    @apply bg-preview-background flex flex-row justify-between px-5 py-3 border-b-2 border-preview-grey-tone-one mobile:min-h-[50px];
}

.preview__nav-left, .preview__nav-right {
    @apply flex flex-row items-center text-preview-text-default;
}

.preview__nav-right {
    @apply mobile:hidden;
}

.preview__back {
    @apply mr-5;
}

.preview__back i {
    @apply mr-1;
}

.preview__nav-right-icon {
    @apply flex justify-center items-center text-3xl w-[48px] h-[48px] p-4 text-preview-text-secondary bg-preview-grey-tone-one mr-2 last:mr-0 rounded-lg;
}

.preview__nav-right-icon--active {
    @apply bg-primary text-preview-text-primary;
}

.preview__nav-right-icon i {
    @apply text-2xl;
}

.preview__iframe {
    @apply w-full h-full transition-all;
}